.footer {
  bottom: "0px";
  background-color: var(--dark);
  width: 100%;
  display: flex;
  justify-content: space-between;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  font-family: Tenderness;
}
#notifyToast {
  color: #fff;
  border-radius: 6px;
  width: 200px;
  margin-left: 75px;
  background-color: var(--green);
}

.loginDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.footer-break {
  flex-basis: 100%;
  height: 0;
}
