.About {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  background-color: #dfe8e8;
  font-family: Tenderness;
}

.About-banner {
  max-width: 1000px;
  width: 100%;
  height: 100%;
  resize: inherit;
  aspect-ratio: 4.5/2;
}

.About-outside {
  background-color: #dfe8e8
}
