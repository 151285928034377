.Contact {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* filter: grayscale(70%); */
  z-index: 1;
}

.Contact-container {
  position: relative;
  z-index: 9999;
  width: 1000px;
}

.Contact-inquire {
  color: #dfe8e8;
  font-family: Tenderness;
  padding-bottom: 30px;
}

.Contact-title {
  margin: 0;
  text-align: center;
}
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

:root {
  --light: #d0d1d3;
  --dark: #dfe8e8;
  --green: #012c32;
  --hover: #037c8b;
}

.formRow {
  margin: 10px 0 20px 0;
}

.formInput {
  padding: 15px 10px;
  background-color: #dfe8e8;
  box-shadow: 0 0 0 1px var(--green);
  border: none;
  color: var(--green);
}

.formInput:focus {
  background-color: #dfe8e8;
  outline: none;
  box-shadow: 0 0 0 1px var(--green);
  color: var(--light);
}

.formInput::placeholder {
  color: var(--green);
  opacity: 1;
}

.formInput:focus::placeholder {
  color: var(--light);
  opacity: 1;
}

.errorMessage {
  color: #f45532;
  font-size: 14px;
  margin: 0;
  padding: 0;
}

#notifyToast {
  color: #fff;
  border-radius: 6px;
  width: 200px;
  margin-left: 75px;
  background-color: var(--green);
}

.submit-btn {
  background-color: var(--green);
  color: var(--dark) !important;
  border: 2px solid var(--green);
  transition: top 0.2s ease;
}
.submit-btn:hover {
  cursor: pointer;
}

.submit-btn:disabled:hover {
  background-color: var(--dark);
  color: var(--green) !important;
  cursor: wait;
}

@media (max-width: 768px) {
  .submit-message {
    width: 125px;
    margin-left: 200px;
  }
}
