.loginButton {
  background-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  font-size: 200px;
  padding: 10px 60px;
  border-radius: 50px;
  margin: 10px 0px;
  cursor: pointer;
}
.App-header {
  display: flex;
  justify-content: space-between;
}

.loginDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

@font-face {
  font-family: "Bungee Inline", cursive;
  src: url("https://fonts.googleapis.com/css?family=Bungee+Inline");
}

/* Cinzel
font-family: 'Cinzel', serif; */
@import url("https://fonts.googleapis.com/css2?family=Cinzel&family=Luxurious+Roman&display=swap");
/* Josefin Sans 
font-family: 'Josefin Sans', sans-serif; */
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital@0;1&display=swap");
/* Marcellus SC 
font-family: 'Marcellus SC', serif; */
@import url("https://fonts.googleapis.com/css2?family=Marcellus+SC&display=swap");
/* Antic Didone 
font-family: 'Antic Didone', serif; */
@import url("https://fonts.googleapis.com/css2?family=Antic+Didone&display=swap");
/* BonvenoCF */
@font-face {
  font-family: BonvenoCF;
  src: url("./fonts/BonvenoCF-Light.otf") format("opentype");
}
/* Capitalis Minimalis */
@font-face {
  font-family: Capitalis Minimalis;
  src: url("./fonts/minicaps.ttf") format("opentype");
}
/* Trajanus */
@font-face {
  font-family: Trajanus;
  src: url("./fonts/TRAJANUS.TTF") format("opentype");
}
/* Gilda Display 
font-family: 'Gilda Display', serif; */
@import url("https://fonts.googleapis.com/css2?family=Gilda+Display&display=swap");
/* Tenderness */
@font-face {
  font-family: Tenderness;
  src: url("./fonts/Tenderness.otf") format("opentype");
}
