.App {
  text-align: left;
  position: relative;
}

:root {
  --fadeinTime: 3.5s;
  --btnWait: 400ms;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  display: flex;
  justify-content: space-between;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #012c32;
}

.Homepage-ygg {
  position: relative;
  background-color: #012c32;
}

.Homepage-ygg::before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 98vh;
  opacity: 0.2;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: top;
  background-size: cover;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 88vh;
}

.child {
}

.button {
  background-color: black;
  color: white;
  font-size: 20px;
  padding: 10px 60px;
  border-radius: 50px;
  margin: 10px 0px;
  cursor: pointer;
}

.loginButton {
  background-color: black;
  color: white;
  font-size: 20px;
  padding: 10px 60px;
  border-radius: 50px;
  margin: 10px 0px;
  cursor: pointer;
}

.loginDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.quote {
  font-size: 6vw;
  margin: 0;
  color: rgb(213, 213, 213);
  text-align: left;
  font-family: Tenderness;
  font-size: 70px;
  letter-spacing: normal;
  -webkit-animation: fadein var(--fadeinTime); /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein var(--fadeinTime); /* Firefox < 16 */
  -ms-animation: fadein var(--fadeinTime); /* Internet Explorer */
  -o-animation: fadein var(--fadeinTime); /* Opera < 12.1 */
  animation: fadein var(--fadeinTime);
}

.mobile-quote {
  margin: 0;
  color: rgb(213, 213, 213);
  text-align: center;
  font-family: Tenderness;
  font-size: 45px;
  letter-spacing: normal;
  -webkit-animation: fadein var(--fadeinTime); /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein var(--fadeinTime); /* Firefox < 16 */
  -ms-animation: fadein var(--fadeinTime); /* Internet Explorer */
  -o-animation: fadein var(--fadeinTime); /* Opera < 12.1 */
  animation: fadein var(--fadeinTime);
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeinBtn {
  /* from {
    opacity: 0;
  } */
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadeinBtn {
  /* from {
    opacity: 0;
  } */
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeinBtn {
  /* from {
    opacity: 0;
  } */
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadeinBtn {
  /* from {
    opacity: 0;
  } */
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadeinBtn {
  /* from {
    opacity: 0;
  } */
  to {
    opacity: 1;
  }
}

.svg-wrapper {
  height: 60px;
  margin: 0 auto;
  position: relative;
  width: 320px;
}

.shape {
  fill: transparent;
  stroke-dasharray: 140 540;
  stroke-dashoffset: -474;
  stroke-width: 8px;
  stroke: #012c32;
  opacity: 0;
  -webkit-animation: fadeinBtn var(--fadeinTime) ease var(--btnWait); /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeinBtn var(--fadeinTime) ease var(--btnWait); /* Firefox < 16 */
  -ms-animation: fadeinBtn var(--fadeinTime) ease var(--btnWait); /* Internet Explorer */
  -o-animation: fadeinBtn var(--fadeinTime) ease var(--btnWait); /* Opera < 12.1 */
  animation: fadeinBtn var(--fadeinTime) ease var(--btnWait);
  animation-fill-mode: forwards;
}
/* svg:hover {
		padding-top: -20px;
		box-shadow: 0px 5px 10px -4px rgba(0, 0, 0, 0.3);
		cursor: pointer;
}  */
.Homepage-btntext {
  color: #fff;
  font-family: "Roboto Condensed";
  font-size: 22px;
  letter-spacing: 8px;
  line-height: 32px;
  position: relative;
  top: -48px;
  transition: top 0.2s ease;
  opacity: 0;
  -webkit-animation: fadeinBtn var(--fadeinTime) ease var(--btnWait); /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeinBtn var(--fadeinTime) ease var(--btnWait); /* Firefox < 16 */
  -ms-animation: fadeinBtn var(--fadeinTime) ease var(--btnWait); /* Internet Explorer */
  -o-animation: fadeinBtn var(--fadeinTime) ease var(--btnWait); /* Opera < 12.1 */
  animation: fadeinBtn var(--fadeinTime) ease var(--btnWait);
  animation-fill-mode: forwards;
}

.Homepage-btntext:hover {
  top: -50px;
  cursor: pointer;
}

@keyframes draw {
  0% {
    stroke-dasharray: 140 540;
    stroke-dashoffset: -474;
    stroke-width: 8px;
  }
  100% {
    stroke-dasharray: 760;
    stroke-dashoffset: 0;
    stroke-width: 2px;
  }
}

.svg-wrapper:hover .shape {
  -webkit-animation: 0.5s draw linear forwards;
  animation: 0.5s draw linear forwards;
}
/* 85px is h1 */

.ralph {
  color: rgb(213, 213, 213);
  font-family: Tenderness;
  font-size: 20px;
  -webkit-animation: fadein var(--fadeinTime); /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein var(--fadeinTime); /* Firefox < 16 */
  -ms-animation: fadein var(--fadeinTime); /* Internet Explorer */
  -o-animation: fadein var(--fadeinTime); /* Opera < 12.1 */
  animation: fadein var(--fadeinTime);
}

.mobile-ralph {
  color: rgb(213, 213, 213);
  font-family: Tenderness;
  font-size: 16px;
  text-align: right;
  -webkit-animation: fadein var(--fadeinTime); /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein var(--fadeinTime); /* Firefox < 16 */
  -ms-animation: fadein var(--fadeinTime); /* Internet Explorer */
  -o-animation: fadein var(--fadeinTime); /* Opera < 12.1 */
  animation: fadein var(--fadeinTime);
}

/* 
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  } */
