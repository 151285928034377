.Services {
  background-color: #dfe8e8;
}

.Services-box {
  max-width: 1000px;
  margin: 0 auto;
}

.Services-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  margin-bottom: 50px;
}

.Services-header {
  margin-top: 0;
  text-align: left;
  margin-bottom: 0;
  font-size: 45px;
  font-family: Tenderness;
  color: #012c32;
  min-width: 200px;
}
.Services-desc {
  margin-top: 0;
  text-align: left;
  margin-bottom: 0;
  font-size: 17px;
  font-family: Tenderness;
  color: #8b8b8b;
  transition: font-size 0.5s ease, color 0.5s ease, text-align 0.5s ease;
  min-width: 200px;
}

.Services-desc:hover {
  color: #262626;

  /* font-size: 15 px; */
}

.Services-div {
  flex: 1;
  align-items: center;
}

.Services-who {
  padding-top: 8%;
  margin-bottom: 4%;
}

.Services-linebox-container {
  display: flex;
  flex-direction: row;
  margin-top: 80px;
  padding-bottom: 20px;
}

.Services-linebox {
  display: flex;
  flex-direction: column;
}

.Services-linebox {
  flex-grow: 2;
}

.Services-about-btn {
  border: none;
  border-left: 2px solid #012c32;
  border-right: 2px solid #012c32;
  background-color: var(--dark);
  font-family: Tenderness;
  font-size: 20px;
  color: #012c32;
}

.Services-about-btn:hover {
  border: none;
  border-left: 2px solid #012c32;
  border-right: 2px solid #012c32;
  background-color: var(--dark);
  font-family: Tenderness;
  font-size: 20px;
  color: #037c8b;
}
