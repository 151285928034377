.Login {
  text-align: left;
  background-color: var(--light);
}

.Login-container {
  background-color: var(--dark);
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.Login-title {
  color: var(--green);
  font-size: 27px;
  font-family: Tenderness;
  margin-bottom: 35px;
  text-align: center;
}

.Login-logo {
  height: 17vh;
}

.Login-card {
  background-color: #d3dfdf;
  min-width: 200px;
  width: 300px;
  height: 350px;
  /* box-shadow: 5px 5px 5px grey; */
  border: 1px solid var(--green);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto auto;
  flex-direction: column;
}

.Login-label {
  text-align: center;
  font-family: Tenderness;
}

.Login-form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Login-error-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Login-submit {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}

.Login-submit-btn {
  background-color: #d3dfdf;
  color: var(--green) !important;
  border: 0px solid var(--green);
  margin-bottom: 0px;
  width: 80px;
  font-family: Tenderness;
  font-size: 20px;
}

.Login-submit-btn:hover {
  color: var(--hover) !important;
}

.Login-submit-btn:active {
  color: var(--green) !important;
}

.Login-submit-btn:disabled:hover {
  background-color: var(--dark);
  color: var(--green) !important;
  cursor: wait;
}

.Login-formInput {
  padding: 15px 10px;
  background-color: white;
  box-shadow: 0 0 0 1px var(--green);
  border: none;
  color: var(--green);
  width: 250px;
  height: 30px;
  margin-bottom: 20px;
}

.Login-formInput:focus {
  background-color: white;
  outline: none;
  box-shadow: 0 0 0 1px var(--green);
  color: var(--green);
}

.Login-formInput::placeholder {
  color: var(--light);
  opacity: 1;
  font-style: italic;
  transition: color 0.2s ease;
}

.Login-formInput:focus::placeholder {
  color: var(--light);
  opacity: 1;
  font-style: italic;
  transition: color 0.2s ease;
}
